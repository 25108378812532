import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/enviroment';
import { BehaviorSubject, EMPTY, Observable, concat, concatMap, concatWith, finalize, mergeMap, tap, catchError } from "rxjs";
import { IForgetPasswordReq, IForgetPasswordRes, ILoginReq, ILoginRes, IOtpReq, IOtpRes, IRegisterReq, IRegisterRes, IResetPasswordReq, IResetPasswordRes } from '@core/interfaces/auth.interface';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationsMessagesEnum } from '@core/enums/notifications-messages.enum';
import { StorageTypes } from '@core/enums/storage-types.enum';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { RegisteredBehaviorsEnum } from '@core/enums/registered-behaviors.enum';
import { LanguagesService } from '../languages.service';

@Injectable({
  providedIn: "root",
})

export class AuthService {
  // ##Injection sector
  private _http = inject(HttpClient);
  private _notificationService = inject(NzNotificationService);
  private _TranslateService = inject(TranslocoService);
  private _Router:Router =  inject(Router);
       
  private readonly BASE_URL: string = environment.endPointUrl;
  private readonly LOGIN_URL: string = `${this.BASE_URL}login`;
  private readonly REGESTER_URL: string = `${this.BASE_URL}register`;
  private readonly FORGET_PASS_URL: string = `${this.BASE_URL}reset-password`;
  private readonly EMAIL_VERIFY_URL: string = `${this.BASE_URL}email-verification`;
  private readonly RESET_PASSWORD_URL: string = `${this.BASE_URL}reset-password`;
  private readonly OTP_URL: string = `${this.BASE_URL}otp`;
    
  private _notificationMessages = NotificationsMessagesEnum;
  private _storageType =  StorageTypes.SESSION_STORAGE ;
  public _isLoginSubject$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.hasToken());
  public getUserData = this.getUser() || null;
  //TOKEN MANAGER METHODS
  public setToken(token: string, storageType:StorageTypes = this._storageType): void {
    switch (storageType) {
      case StorageTypes.LOCAL_STORAGE:
        localStorage.setItem("token", token);
        break;
      case StorageTypes.SESSION_STORAGE:
         window.sessionStorage.setItem("token", token)
        break;
      case StorageTypes.BOTH:
        localStorage.setItem("token", token)
        window.sessionStorage.setItem("token", token)
        break;
      default:
          window.sessionStorage.setItem("token", token)
        break;
    }
  }
  
  private setUser(userData:any, storageType:StorageTypes = this._storageType): void {
    switch (storageType) {
      case StorageTypes.LOCAL_STORAGE:
        localStorage.setItem("user",  JSON.stringify(userData))
        break;
      case StorageTypes.SESSION_STORAGE:
         window.sessionStorage.setItem("user", JSON.stringify(userData))
        break;
      case StorageTypes.BOTH:
        localStorage.setItem("user", JSON.stringify(userData))
        window.sessionStorage.setItem("user", JSON.stringify(userData))
        break;
      default:
          window.sessionStorage.setItem("user", JSON.stringify(userData))
        break;
    }
  }
  
  private getUser(storageType:StorageTypes = this._storageType) {
    switch (storageType) {
      case StorageTypes.LOCAL_STORAGE:
        return JSON.parse(localStorage.getItem("user") as string)
      case StorageTypes.SESSION_STORAGE:
         return JSON.parse(sessionStorage.getItem("user") as string)
      case StorageTypes.BOTH:
        return JSON.parse(localStorage.getItem("user") as string)
        // return JSON.parse(window.sessionStorage.getItem("user") as string)
      default:
          return JSON.parse(window.sessionStorage.getItem("user") as string)
    }
  }
  
  private removeUserData(): void {
    localStorage.removeItem("user");
    window.sessionStorage.removeItem("user");
  }
  
  private removeToken(): void {
    localStorage.removeItem("token");
    window.sessionStorage.removeItem("token");
  }
  
  private hasToken(): boolean {
    return !!localStorage.getItem("token") || !!window.sessionStorage.getItem("token");
  }
  
  private authenticateUser(
    res: ILoginRes | IRegisterRes,
    authenticationType: 'LOGIN'|'REGESTER'
    ): Observable<string>{
      if (res?.data?.token) {
          this.setToken(res.data.token);
          this.setUser(res.data.user);
          this._isLoginSubject$.next(true);
          return this._TranslateService
          .selectTranslate(`notificatoins.${
            authenticationType === 'LOGIN'?this._TranslateService.translate('Login.success')
            :this._TranslateService.translate('Register.success')
          }`)
          .pipe(
            tap(message => {
              this._notificationService.success(
                message,
                ""
              );
            }),
            finalize(() => {
              authenticationType === 'LOGIN'?this._Router.navigate(['/']):
              this.redirectRegisterededUser(res.message as RegisteredBehaviorsEnum)
            })
          )
        } 
        return EMPTY; 
  }
  
  private redirectRegisterededUser(message:RegisteredBehaviorsEnum): void {
    switch (message) {
      case RegisteredBehaviorsEnum.EMAIL_VERIFY:
        // this._Router.navigate(['/auth/email-verification'])
        
        // TODO: add email verification transition
        this._notificationService.info(
          'Please verify your email',
          'Please verify your email'
        );
        break;
      case RegisteredBehaviorsEnum.PHONE_VERIFY:
        // this._Router.navigate(['/auth/login'])
        
        // TODO: add phone verification transition
        this._notificationService.info(
          'Please verify your phone',
          'Please verify your phone'
        );
        break;
      default:
        this._Router.navigate(['/user'])
  }
  }
  
  public getToken(): string {
  let token: string = '';

  switch (this._storageType) {
    case StorageTypes.LOCAL_STORAGE:
      token = localStorage.getItem("token") as string;
      break;
    case StorageTypes.SESSION_STORAGE:
      token = window.sessionStorage.getItem("token") as string;
      break;
    case StorageTypes.BOTH:
      token = localStorage.getItem("token") as string
      token = window.sessionStorage.getItem("token") as string
      break;
    default:
        token = window.sessionStorage.getItem("token") as string
      break;
  }
  return token;
  }
  
  public isLoggedIn(): Observable<boolean> {
    return this._isLoginSubject$.asObservable();
  }
  
  public register(registerData: IRegisterReq): Observable<IRegisterRes> {
    return this._http.post<IRegisterRes>(this.REGESTER_URL, registerData)
    .pipe(
      concatMap(res => this.authenticateUser(res, 'REGESTER')),
      catchError(err => {
          return this._TranslateService
          .selectTranslate(`notifications.Register.success`)
          .pipe(
            tap(message => {
              this._notificationService.error(
                this._TranslateService.translate(`notifications.Register.success`),
                err?.error?.message || message
              );
            })
          )
        }
      ),
    );
  }

  public login(loginData: ILoginReq): Observable<ILoginRes> {
    return this._http.post<ILoginRes>( this.LOGIN_URL,loginData).pipe(
      mergeMap(res => this.authenticateUser(res, 'LOGIN')),
      catchError(err => {
          return this._TranslateService
          .selectTranslate(`notifications.Login.success`)
          .pipe(
            tap(message => {
              this._notificationService.error(
                message,
                err?.error?.message || message
              );
            })
          )

      })
    );
  }

  //TODO: check forget pass response
  public forgetPassword(
    forgetPasswordData: IForgetPasswordReq
  ): Observable<IForgetPasswordRes> {
    return this._http.get<IForgetPasswordRes>(this.FORGET_PASS_URL, {
      params: new HttpParams({
        fromObject: {
          params: JSON.stringify(forgetPasswordData),
        },
      }),
    });
  }

  public emailVerify() {
    return this._http.get(this.EMAIL_VERIFY_URL);
  }

  public resetPassword(
    restPasswordData: IResetPasswordReq
  ): Observable<IResetPasswordRes> {
    return this._http.post<IResetPasswordRes>(
      this.RESET_PASSWORD_URL,
      restPasswordData
    );
  }

  public generateOtp(): Observable<any> {
    return this._http.get(this.OTP_URL);
  }

  public sendOtp(otp: IOtpReq): Observable<IOtpRes> {
    return this._http.post<IOtpRes>(this.OTP_URL, otp);
  }
  
  //NOTE Ignore it as it is not used
  public logout(): any {
    this.removeToken();
    this.removeUserData();
    this._isLoginSubject$.next(false);
  }
  
}

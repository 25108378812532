import { HttpClient } from "@angular/common/http";
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from "@ngneat/transloco";
import { inject, Injectable, isDevMode, NgModule } from "@angular/core";
import { environment } from "@env/enviroment";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  private _http: HttpClient = inject(HttpClient);

  getTranslation(lang: string) {
    return this._http.get<Translation>(`./assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ["en", "ar"],

        defaultLang: window.localStorage.getItem("lang") ?? "en",
        reRenderOnLangChange: true,
        prodMode: environment.production,
        flatten: {
          aot: !isDevMode(),
        },
        missingHandler: {
          logMissingKey: true,
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}

import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum buttonTypes{
  SUBMIT = 'submit',
  CANCEL = 'cancel',
}

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss']
})
export class CardLayoutComponent {
  @Input() title: string="";
  @Input() actionAbled: boolean=false;
  @Input() actionButtons: Array<{type: buttonTypes, text: string}> = [];
  @Output() action: EventEmitter<{type: buttonTypes, text: string}> = new EventEmitter();
  
  public buttonTypes = buttonTypes;
  public actionClicked(button: {type: buttonTypes, text: string}):void{
    this.action.emit(button);
  }
}

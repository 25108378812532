<div class="country-input" [ngClass]="lang == languages.ARABIC?'rtl':''" >
    <nz-select
    nzShowSearch 
    nzAllowClear 
    id="country-list"
    class="input-tahkeem select"
    class="input mb-0"
    [formControl] ="control"
    [nzPlaceHolder]="placeholder|async" >
        <nz-option 
        *ngFor="let country of (countryList|async)?.data" 
        [nzLabel]="country.name" 
        [nzValue]="value??country.id ">
        </nz-option>
    </nz-select>
    <ng-container *ngIf="displayErrors()">
       <div class="errors requried_error">
        <p>
            {{'errors.countryRequired'|transloco}}
        </p>
       </div>
    </ng-container>
</div>
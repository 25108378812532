import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/enviroment';
import { ICountryList } from '../_interfaces/countryList.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private readonly BASE_ENDPOINT = environment.endPointUrl;
  private readonly COUNTRY_LIST_ENDPOINT = `${this.BASE_ENDPOINT}countries`;
  private _http: HttpClient = inject(HttpClient);
  
  get countryList():Observable<{data:ICountryList[],message:string}> {
    return this._http.get<{data:ICountryList[],message:string}>(this.COUNTRY_LIST_ENDPOINT);
  }
}

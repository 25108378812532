<div class="sidebar-logo d-flex align-items-center">
  <img
    src="../../../../assets/img/auth/logo.png"
    height="100"
    class="mx-auto"
    alt="logo"
  />
</div>

<!-- To Check if loaded or resize ,,,, Handel sidebar behavior -->
<div (window:resize)="onSizeChange()"></div>
<div (window:loaded)="onSizeChange()"></div>

<div id="list-side" [class.rtl]="activeLang == 'ar'">
  <ul
    class="list-menu"
    nz-menu
    nzMode="inline"
    [nzInlineCollapsed]="isCollapsed | async"
  >
    <ng-container *transloco="let t">
      <div class="sub-title">
        <h4>{{ t("sidebar.Menu") }}</h4>
      </div>

      <ng-container
        *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"
      ></ng-container>
      <ng-template #menuTpl let-menus>
        <ng-container *ngFor="let menu of menus; let i = index">
          <li
            *ngIf="!menu.children"
            [routerLink]="menu.route"
            [nzMatchRouter]="true"
            [nzMatchRouterExact]="true"
            nz-menu-item
            [nzDisabled]="menu.disabled"
            [nzSelected]="menu.selected"
          >
            <i [class]="menu.icon"></i>
            <span>{{ menu.title }}</span>
          </li>
          <li
            *ngIf="menu.children"
            nz-submenu
            [routerLink]="menu.children.route"
            [(nzOpen)]="menu.open"
            [nzTitle]="titleSubmenu"
            [routerLink]="menu.route"
            [nzDisabled]="menu.disabled"
          >
            <ul>
              <ng-container
                *ngTemplateOutlet="
                  menuTpl;
                  context: { $implicit: menu.children }
                "
              >
              </ng-container>
            </ul>
          </li>
          <ng-template #titleSubmenu>
            <i [class]="menu.icon"></i>
            {{ menu.title }}
          </ng-template>
        </ng-container>
      </ng-template>
    </ng-container>
  </ul>
</div>

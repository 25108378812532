import { Component, Input, inject } from '@angular/core';
import { Languages } from '@core/enums/languages.enum';
import { TranslocoService } from '@ngneat/transloco';
import { CountryService } from '../../_services/country.service';
import { Observable } from 'rxjs';
import { ICountryList } from '../../_interfaces/countryList.interface';

@Component({
  selector: 'app-country-list-input',
  templateUrl: './country-list-input.component.html',
  styleUrls: ['./country-list-input.component.scss'],
})
export class CountryListInputComponent {
  private _translocoService:TranslocoService = inject(TranslocoService);
  
  @Input() public control:any;
  @Input() public value:any;
  
  public placeholder: Observable<string> = this._translocoService.selectTranslate("Inputs.CountryList");
  public countryList : Observable<{data:ICountryList[]}> = inject(CountryService).countryList;
  public lang:Languages = window.localStorage.getItem('lang') as Languages;
  public languages = Languages;
  
  displayErrors() {
    const { dirty, touched, errors } = this.control;
    return dirty && touched && errors;
  }
  
  compareFn = (o1: any, o2: any) => (o1 === o2);

}

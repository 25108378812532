import { Component, inject } from '@angular/core';
import { LayoutUiService } from '@layout/_services/layout-ui.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  private _layoutUI:LayoutUiService = inject(LayoutUiService);
  
  public isCollapsed = this._layoutUI._isCollapsedSideBarStatus$;
  
  public changeCollapsed(Event:any){
    this.isCollapsed.next(Event);
  }
}

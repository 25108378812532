import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Errors } from '@core/enums/status-code.enum';
import { AuthService } from '@core/services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ErrorHandelerInterceptor implements HttpInterceptor {
  // ##Injection sector
  private _toaster = inject(NzNotificationService);
  private _translateService = inject( TranslocoService);
  private _authService = inject( AuthService);
    
  private _errorTitle:string = '';

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authEndPoints: string[] = ['/login', '/register'];    
    const translateEndPoints: string[] = [
      '/assets/i18n/en.json',
      '/assets/i18n/ar.json'
    ];
    const isAuthEndPoint: boolean = authEndPoints.concat(translateEndPoints).some((endPoint) => request.url.includes(endPoint));
    
    this._translateService.selectTranslate("notifications.ERROR")
    .subscribe((s)=>{
      this._errorTitle = s;
    })
    
    if (isAuthEndPoint) return next.handle(request);

    return next.handle(request).pipe(
      
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string = '';
        
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${this._errorTitle}: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `${this._errorTitle}: ${error.status}\nMessage: ${error.message}`;

          switch (error.status) {
            case Errors.BAD_REQUEST:
              errorMessage = 'Bad Request';
              break;
              
            case Errors.UNAUTHORIZED:
              errorMessage = 'Unauthorized';
              this._authService.logout();
              break;
              
            case Errors.FORBIDDEN:
              errorMessage = 'Forbidden';
              break;
              
            case Errors.NOT_FOUND:
              errorMessage = 'Not Found';
              break;
              
            case Errors.CONFLICT:
              errorMessage = 'Conflict';
              break;
              
            default:
              errorMessage = 'Unknown Error';
              break;
          }
        }
        
        this._toaster.error(`${this._errorTitle}:`, errorMessage);
        return throwError(() => errorMessage);
      })
    )
  }
}

import {
  Component,
  EventEmitter,
  Input,
  NgIterable,
  Output,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { ColumnItem } from "../../_interfaces/column-item";
export enum actions {
  edit = "edit",
  delete = "delete",
  view = "view",
}

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  @Input() public listOfData: { [key: string]: any }[] = [];
  @Input() public listOfColumns: ColumnItem<any>[] = [];
  @Input() public actionsButtons?: actions[];
  @Input() public loadingTable?: boolean;
  @Input() public HandelHtmlStrings?: boolean;

  public actions = actions;
  // * data : is the data of the row;
  // * name : is the name of the button and it should be uniqe ;
  @Output() public actionButtonClicked = new EventEmitter<{
    name: actions;
    data: any;
  }>();

  // * columnsKeys should be the same in each row ....
  public columnsKeys = Object.keys(this.listOfData[0] || {});

  public actionEmitter(name: actions, data: { [key: string]: any }) {
    this.actionButtonClicked.emit({ name, data });
  }

  ngOnInit(): void {
    this.columnsKeys = Object.keys(this.listOfData[0] || {});
  }
}

import { Component, Input, EventEmitter, Output } from "@angular/core";

export interface BreadCrumbItem {
  title: string;
  link: string;
}

@Component({
  selector: "app-bread-crumb",
  templateUrl: "./bread-crumb.component.html",
  styleUrls: ["./bread-crumb.component.scss"],
})
export class BreadCrumbComponent {
  @Input() sectionTitle: string = "Sec Title";
  @Input() BreadCrumbSeperator: string = "-";
  @Input() breadCrumbList: BreadCrumbItem[] = [{ title: "", link: "" }];
  @Input() headerActionTitle: string = "Action";
  @Input() isActionExist?: boolean = true;
  @Output() headerAction: EventEmitter<undefined> =
    new EventEmitter<undefined>();

  public emitHeaderAction() {
    this.headerAction.emit();
  }
}

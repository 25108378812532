<div class="app-header">
  <div class="d-flex justify-content-between align-items-center h-100">
    <div class="header-trigger">
      <span
        class="trigger"
        [class.collapsed]="isCollapsed | async"
        (click)="updateIsCollapsed()"
      >
        <svg
          width="21"
          height="19"
          viewBox="0 0 24 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="14"
            height="3"
            rx="1.5"
            transform="matrix(-1 0 0 1 24 0)"
            fill="#d2ab39"
          ></rect>
          <rect
            width="24"
            height="3"
            rx="1.5"
            transform="matrix(-1 0 0 1 24 9)"
            fill="#d2ab39"
          ></rect>
          <rect
            width="18"
            height="3"
            rx="1.5"
            transform="matrix(-1 0 0 1 24 18)"
            fill="#d2ab39"
          ></rect>
        </svg>
      </span>
      <div
        class="form-tahkeem"
        [class.d-none]="!(isCollapsed | async) && searchInput"
      >
        <div
          class="input-container d-flex justify-content-center align-items-center"
        >
          <nz-input-group [nzSuffix]="search">
            <input
              type="text"
              id="search"
              [(ngModel)]="searchParem"
              class="input-tahkeem"
              [placeholder]="'navbar.Search' | transloco"
            />
          </nz-input-group>
          <ng-template #search>
            <span nz-icon nzType="search" nzTheme="outline"></span>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="routes-nav d-flex align-items-center justify-content-between">
      <span (click)="switchLang()" class="lang-icon">
        {{ "general.Language" | transloco }}
      </span>
      <span class="notification-icon">
        <i class="fonticon-alarm fs-3"></i>
      </span>
      <span class="settings-icon d-none d-md-block">
        <i class="fonticon-settings fs-2"></i>
      </span>
      <span class="avatar-icon">
        <nz-avatar
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="menu"
          [nzSize]="40"
          nzIcon="user"
          nzSrc="https://media.istockphoto.com/id/1201514204/vector/person-gray-photo-placeholder-man.jpg?s=612x612&w=0&k=20&c=a-fxeEPpMhBWhYhlwUWKGx1g0YOC80tPGxBYsRy4m-U="
        ></nz-avatar>
        <nz-dropdown-menu #menu="nzDropdownMenu" class="ant-dropdown-menu-rtl">
          <ul nz-menu>
            <li nz-menu-item class="py-3">
              <div class="img-profile d-flex align-items-center">
                <img
                  src="https://media.istockphoto.com/id/1201514204/vector/person-gray-photo-placeholder-man.jpg?s=612x612&w=0&k=20&c=a-fxeEPpMhBWhYhlwUWKGx1g0YOC80tPGxBYsRy4m-U="
                  width="50"
                  height="50"
                  alt="user profile"
                />
                <div class="data">
                  <h6>
                    {{
                      profileData?.first_name + " " + profileData?.middle_name
                    }}
                  </h6>
                  <p>{{ profileData?.email }}</p>
                </div>
              </div>
            </li>
            <li nz-menu-item>{{ "navbar.MyProfile" | transloco }}</li>
            <li nz-submenu nzTitle="{{ 'navbar.Languages' | transloco }}">
              <ul class="submenu">
                <li nz-menu-item (click)="switchLang()">
                  <img
                    class="rounded-1"
                    width="20"
                    height="20"
                    src="./../../../../../../assets/img/flag/united-states.svg"
                    alt="united states"
                  />

                  <span>
                    {{ "navbar.English" | transloco }}
                  </span>
                </li>
                <li nz-menu-item (click)="switchLang()">
                  <img
                    class="rounded-1"
                    width="20"
                    height="20"
                    src="./../../../../../../assets/img/flag/saudi-arabia.svg"
                    alt="saudi arabia"
                  />
                  <span>{{ "navbar.Arabia" | transloco }}</span>
                </li>
              </ul>
            </li>
            <li nz-menu-item (click)="logout()">
              {{ "navbar.SignOut" | transloco }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </span>
    </div>
  </div>
</div>

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NZ_I18N } from "ng-zorro-antd/i18n";
import { en_US } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslocoRootModule } from "./transloco-root.module";
import { CoreModule } from "@core/core.module";
import { LayoutModule } from "@layout/layout.module";
import { IconsProviderModule } from "./icons-provider.module";
import { EditorModule } from "@tinymce/tinymce-angular";
import { SharedModule } from "./_shared/shared.module";
import { NzSpinModule } from "ng-zorro-antd/spin";

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    CoreModule,
    LayoutModule,
    IconsProviderModule,
    EditorModule,
    SharedModule,
    NzSpinModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent],
})
export class AppModule {}

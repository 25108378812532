import { Component, inject } from "@angular/core";
import { LayoutUiService } from "@layout/_services/layout-ui.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { AuthService } from "../../../_core/services/auth.service";
import { TranslocoService } from "@ngneat/transloco";
import { TranslationService } from "@core/services/translation.service";
import { LanguagesService } from "@core/languages.service";
import { Languages } from "@core/enums";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {
  private _layoutUI = inject(LayoutUiService);
  private _breakpointObserver$ = inject(BreakpointObserver);
  private _authService = inject(AuthService);
  private _translateService = inject(TranslocoService);

  public profileData: any;
  public getScreenWidth: number = 0;
  public isCollapsed = this._layoutUI._isCollapsedSideBarStatus$;
  public searchParem: string = "";
  public searchPlace = "";
  public searchInput: boolean = false;
  public currentLang = window.localStorage.getItem("lang");

  ngOnInit(): void {
    this.onWindowResize();
    this.profileData = this._authService.getUserData;
  }

  private onWindowResize() {
    this.getScreenWidth = this._layoutUI.innerWidth;

    this._breakpointObserver$
      .observe("(max-width: 768px)")
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.searchInput = true;
          this._layoutUI.updateIsCollapsedSideBar(false);
        } else {
          this.searchInput = false;
          this._layoutUI.updateIsCollapsedSideBar(true);
        }
      });
  }

  public updateIsCollapsed(): void {
    this.isCollapsed.next(!this.isCollapsed.getValue());
    this._layoutUI.updateIsCollapsedSideBar(this.isCollapsed.getValue());
  }

  public logout(): void {
    this._authService.logout();
  }

  public switchLang(): void {
    if (this._translateService.getActiveLang() === Languages.ENGLISH) {
      this._translateService.setActiveLang(Languages.ARABIC);
      window.localStorage.setItem("lang", Languages.ARABIC);
      this.currentLang = Languages.ARABIC;
    } else {
      this._translateService.setActiveLang(Languages.ENGLISH);
      window.localStorage.setItem("lang", Languages.ENGLISH);
      this.currentLang = Languages.ENGLISH;
    }
    window.location.reload();
  }
}

import { TranslocoModule } from "@ngneat/transloco";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './_components/table/table.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { BreadCrumbComponent } from './_components/bread-crumb/bread-crumb.component';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { RouterModule } from '@angular/router';
import { CountryListInputComponent } from './_components/country-list-input/country-list-input.component';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CardLayoutComponent } from './_components/card-layout/card-layout.component';
import { GregorianCalenderInputComponent } from './_components/gregorian-calender-input/gregorian-calender-input.component';
import { HejriCalendarInputComponent } from './_components/hejri-calendar-input/hejri-calendar-input.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";



@NgModule({
  declarations: [
    TableComponent,
    BreadCrumbComponent,
    CountryListInputComponent,
    CardLayoutComponent,
    GregorianCalenderInputComponent,
    HejriCalendarInputComponent
  ],
  imports: [
    CommonModule,
    NzTableModule,
    NzBreadCrumbModule,
    RouterModule,
    NzInputModule,
    FormsModule,
    NzSelectModule,
    ReactiveFormsModule,
    TranslocoModule,
    NgbModule

  ],
  exports: [
    TableComponent,
    BreadCrumbComponent,
    CountryListInputComponent,
    ReactiveFormsModule,
    CardLayoutComponent,
    TranslocoModule,
    FormsModule,
    HejriCalendarInputComponent,
    GregorianCalenderInputComponent
  ]
})
export class SharedModule { }

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-gregorian-calender-input',
  templateUrl: './gregorian-calender-input.component.html',
  styleUrls: ['./gregorian-calender-input.component.scss']
})
export class GregorianCalenderInputComponent {
  @Output() gregorian = new EventEmitter<string>();


  onDateSelect(event: any) {
    const year = event.year;
    const month = event.month <= 9 ? '0' + event.month : event.month;
    const day = event.day <= 9 ? '0' + event.day : event.day;
    const finalDate = year + '-' + month + '-' + day;
    this.gregorian.emit(finalDate);
  }
}

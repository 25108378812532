import {
  NzNotificationModule,
  NzNotificationService,
} from "ng-zorro-antd/notification";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandelerInterceptor } from "@core/interceptors/error-handeler.interceptor";
import { JwtHandelerInterceptor } from "@core/interceptors/jwt-handeler.interceptor";

@NgModule({
  imports: [CommonModule],
  exports: [NzNotificationModule],
  providers: [
    NzNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandelerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtHandelerInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}

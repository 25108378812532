import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';

@Injectable()

export class JwtHandelerInterceptor implements HttpInterceptor {
  // ##Injection sector
  private _authService = inject(AuthService);
  private _token = this._authService.getToken();

  private setTokenToHeaders(
      request: HttpRequest<unknown>,
      TOKEN: string, next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${TOKEN}`,
        }
      });
      return next.handle(request);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const allowedEndPoints: string[] = [
      '/countries',
      '/assets/i18n/en.json',
      '/assets/i18n/ar.json'
    ];

    const authEndPoints: string[] = [
      '/login',
      '/logout',
      '/register',
      '/reset-password',
    ]
    const allowedAuthEndPoint = authEndPoints.some((endPoint) => request.url.includes(endPoint) );
    const allowedEndPoint = allowedEndPoints.some((endPoint) => request.url.includes(endPoint) );
    
    let isLoggedIn = ()=> this._authService._isLoginSubject$.getValue();
    
    if((allowedAuthEndPoint && !this._authService._isLoginSubject$.getValue()) || allowedEndPoint){
      return next.handle(request);
    }else{
      if (this._token) {
        return this.setTokenToHeaders(request, this._token, next);
      }else{
        return EMPTY;
      }
    }
  }
}

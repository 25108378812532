import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutUiService {

  public _isCollapsedSideBarStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


  public get innerWidth(): number {
    return window.innerWidth;
  }

  public onResize() {
    let windowInnerWidth: boolean = this.innerWidth > 768 ? false : true;
    this._isCollapsedSideBarStatus$ = new BehaviorSubject<boolean>(windowInnerWidth)
  }


  public updateIsCollapsedSideBar(newCollapsedSideBar: boolean): void {
    this._isCollapsedSideBarStatus$.next(newCollapsedSideBar)
  }

  getIsCollapsedSideBar(): BehaviorSubject<boolean> {
    return this._isCollapsedSideBarStatus$;
  }
}

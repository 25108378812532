import { Component, inject, OnInit } from "@angular/core";
import { Languages } from "@core/enums";
import { TranslocoService } from "@ngneat/transloco";
import { AuthService } from "./_core/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { LanguagesService } from "@core/languages.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public http = inject(HttpClient);
  public title = "tahkeem";
  private _translateService = inject(TranslocoService);
  private _auth = inject(AuthService);
  public currentLang = window.localStorage.getItem("lang");
  public _languagesService = inject(LanguagesService);

  constructor() {
    if (!window.localStorage.getItem("lang")) {
      this._translateService.setActiveLang(Languages.ENGLISH);
      window.localStorage.setItem("lang", Languages.ENGLISH);
    }

    // TOBE_REMOVED
    this.http.get("https://tahkeemv2.almansah.net/api/v1/laws").subscribe();
  }

  ngOnInit(): void {
    this._languagesService.languages.subscribe((language) => {
      this._languagesService.loadedLanguages = language.data;
      localStorage.setItem("languages", JSON.stringify(language.data));
    });
  }

  public logout() {
    this._auth.logout();
    window.location.reload();
  }

  action(action: any) {}
}

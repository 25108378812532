<div class="row align-items-center justify-content-between">
    <div class="col-7">
        <h2 class="title-section">
            {{sectionTitle}}
        </h2>
        <nz-breadcrumb [nzSeparator]="BreadCrumbSeperator">
            <nz-breadcrumb-item *ngFor="let step of breadCrumbList" [routerLink]="step.link">
                {{step.title}}
            </nz-breadcrumb-item>
        </nz-breadcrumb>
    </div>
    <div class="col-5" *ngIf="isActionExist">
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn-add" (click)="emitHeaderAction()">{{headerActionTitle}}</button>
        </div>
    </div>
</div>

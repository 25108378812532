<div class="card-layout">
    <div class="card-header">{{title}}</div>
    <div class="card-body">
        <div class="card-container">
            <ng-content>
                <!-- Content -->
            </ng-content>
        </div>
    </div>
    <div class="card-footer">
        <button 
            *ngFor="let btn of actionButtons"
            [ngClass]="btn.type"
            (click)="actionClicked(btn)"
            class="action-button">
            {{btn.text}}
        </button>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@layout/main-layout/main-layout.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SideBarComponent } from './_components/side-bar/side-bar.component';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FooterComponent } from './_components/footer/footer.component';


@NgModule({
  declarations: [
    AuthLayoutComponent,
    MainLayoutComponent,
    SideBarComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    NzLayoutModule,
    NzAvatarModule,
    NzInputModule,
    FormsModule,
    TranslocoModule,
    NzMenuModule,
    NzIconModule,
    NzToolTipModule,
    NzDropDownModule
  ]
})
export class LayoutModule { }

<section id="auth-wrapper">
  <div class="container vh-100">
    <div class="row h-100 justify-content-between">
      <div class="col-md-5">
        <div class="row align-items-center justify-content-center h-100">
          <div class="col-md-12 py-3">
            <img
              class="mx-auto mx-lg-0 d-block text-center"
              width="300"
              src="../../assets/img/auth/logo.png"
              alt="logo"
            />
            <h2 class="desc" *transloco="let t">{{ t("auth.desc") }}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="row align-items-center justify-content-end h-100">
          <div class="col-md-10">
            <div class="box-auth">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, HostListener, OnInit, inject } from "@angular/core";
import { SidebarService } from "./sidebar.service";
import { LayoutUiService } from "../../_services/layout-ui.service";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  private _SidebarMenusService: SidebarService = inject(SidebarService);
  private _translateService: TranslocoService = inject(TranslocoService);

  public activeLang = this._translateService.getActiveLang();
  public isCollapsed = inject(LayoutUiService)._isCollapsedSideBarStatus$;
  public menus: any[] = [];

  ngOnInit(): void {
    this._SidebarMenusService.getTranslatedMenu().subscribe((data) => {
      this.menus = data;
    });
    this.onSizeChange();
  }

  onSizeChange(): void {
    this.isCollapsed.next(window.innerWidth < 768 ? true : false);
  }
}

import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../enviroments/enviroment';
import { Observable } from 'rxjs';
import { IRes } from './interfaces/https.interfaces';
import { ILanguage } from './interceptors/languages.interface';

@Injectable({
  providedIn: 'root'
})

export class LanguagesService {
  private _BASE_URL:string = environment.endPointUrl;
  private _LANGUAGE_ENDPOINT:string = `${this._BASE_URL}languages`
  private _http:HttpClient = inject(HttpClient);
  public loadedLanguages:ILanguage[]|undefined = [];
  
  get languages():Observable<IRes<ILanguage[]>>{
    return this._http.get<IRes<ILanguage[]>>(this._LANGUAGE_ENDPOINT)
  }
}

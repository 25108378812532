<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" nzCollapsible [nzCollapsedWidth]="0" [nzTheme]="'light'" nzWidth="256px"
        nzBreakpoint="lg" [nzCollapsed]="isCollapsed|async" (nzCollapsedChange)="changeCollapsed($event)"
        [nzTrigger]="null">
        <app-side-bar></app-side-bar>
    </nz-sider>
    <nz-layout>
        <nz-header>
            <app-navbar></app-navbar>
        </nz-header>
        <nz-content>
            <div class="inner-content">
                <router-outlet></router-outlet>
                <app-footer></app-footer>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>

import { TranslocoService, translate } from "@ngneat/transloco";
import { Injectable, inject } from "@angular/core";
import { zip, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  private _translateService = inject(TranslocoService);

  private slidebar = this._translateService.selectTranslateObject("sidebar");
  private permissions =
    this._translateService.selectTranslateObject("Permissions");
  private roles = this._translateService.selectTranslateObject("Roles");
  private dashboard = this._translateService.selectTranslateObject("Dashboard");
  private Law = this._translateService.selectTranslateObject("Law");
  private issues = this._translateService.selectTranslateObject("Issues");

  private translatedData = zip(
    this.slidebar,
    this.permissions,
    this.issues,
    this.roles,
    this.dashboard,
    this.Law
  );

  public getTranslatedMenu() {
    return this.translatedData.pipe(
      map((translatedObj) => {
        return [
          {
            level: 1,
            title: translatedObj[0].Dashboard,
            open: false,
            id: 1,
            icon: "fonticon-house",
            selected: false,
            disabled: false,
            children: [
              {
                level: 2,
                title: "Group 1",
                icon: "bars",
                open: false,
                selected: false,
                disabled: false,
              },
              {
                level: 2,
                title: "Group 2",
                icon: "bars",
                selected: true,
                disabled: false,
              },
              {
                level: 2,
                title: "Group 3",
                icon: "bars",
                selected: false,
                disabled: false,
              },
            ],
          },
          {
            level: 1,
            title: translatedObj[0].Users,
            icon: "fonticon-stats",
            id: 2,
            open: false,
            selected: false,
            disabled: false,
            children: [
              {
                level: 2,
                title: "User 1",
                icon: "user",
                selected: false,
                disabled: false,
              },
              {
                level: 2,
                title: "User 2",
                icon: "user",
                selected: false,
                disabled: false,
              },
            ],
          },
          {
            level: 1,
            title: translatedObj[0].issues,
            open: false,
            id: 3,
            icon: "fonticon-attachments",
            selected: false,
            disabled: false,
            children: [
              {
                level: 2,
                title: translatedObj[2].IssuesList,
                selected: false,
                disabled: false,
                route: "/issues",
              },
              {
                level: 2,
                title: translatedObj[2].CreateIssues,
                selected: false,
                disabled: false,
                route: "/issues/create-issue",
              },
            ],
          },
          {
            level: 1,
            title: translatedObj[0].roles,
            open: false,
            id: 4,
            icon: "fonticon-remote-control",
            selected: false,
            disabled: false,
            children: [
              {
                level: 2,
                title: translatedObj[3].RolesList,
                selected: false,
                disabled: false,
                route: "/roles",
              },
              // {
              //   level: 2,
              //   title: 'Roles.CreateRoles',
              //   selected: false,
              //   disabled: false,
              //   route: '/roles/create-role'
              // },
            ],
          },
          {
            level: 1,
            title: translatedObj[0].permissions,
            open: false,
            id: 4,
            icon: "fonticon-share",
            selected: false,
            disabled: false,
            children: [
              {
                level: 2,
                title: translatedObj[1].PermissionsList,
                selected: false,
                disabled: false,
                route: "/permissions",
              },
              // {
              //   level: 2,
              //   title: translatedObj[1].CreatePermissions,
              //   selected: false,
              //   disabled: false,
              //   route: '/permissions/create-permission'
              // },
            ],
          },
          {
            level: 1,
            title: translatedObj[0].law,
            open: false,
            id: 4,
            icon: "fonticon-alignment-right",
            selected: false,
            disabled: false,
            children: [
              {
                level: 2,
                title: translatedObj[5].lawList,
                selected: false,
                disabled: false,
                route: "/law/list",
              },
              {
                level: 2,
                title: translatedObj[5].CreateLaw,
                selected: false,
                disabled: false,
                route: "/law/insert-law",
              },
              // {
              //   level: 2,
              //   title: translatedObj[1].CreatePermissions,
              //   selected: false,
              //   disabled: false,
              //   route: '/permissions/create-permission'
              // },
            ],
          },
        ];
      })
    );
  }
}

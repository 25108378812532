<div class="input-group">
     <input class="input-tahkeem" (click)="dp.toggle()" placeholder="yyyy-mm-dd" name="hijriDate"
          (dateSelect)="onDateSelect($event)" [firstDayOfWeek]="7" ngbDatepicker #dp="ngbDatepicker"
          [minDate]="{year: 1434, month: 1, day: 1}" [maxDate]="{year: 1454, month: 12, day: 31}">
     <!--
  <ngb-datepicker class="rtl"
    #dp [(ngModel)]="model" [firstDayOfWeek]="7"> </ngb-datepicker> -->
     <button class="btn-calnder btn btn-outline-secondary d-flex justify-content-center align-items-center"
          (click)="dp.toggle()" type="button">
          <span nz-icon nzType="calendar" nzTheme="outline"></span>
     </button>
</div>

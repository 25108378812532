<section id="footer">
     <div class="container">
          <div class="row align-items-center justify-content-between">
               <div class="col-3">
                    <p>2023© <span>{{"general.Tahkemm" | transloco}}</span></p>
               </div>
               <div class="col-2">
                    <ul class="list-unstyled d-flex justify-content-between align-items-center">
                         <li>
                              {{"general.About" | transloco}}
                         </li>
                         <li>
                              {{"general.Support" | transloco}}
                         </li>
                    </ul>
               </div>
          </div>
     </div>
</section>

import { NgModule, inject } from "@angular/core";
import { Router, RouterModule, Routes } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { MainLayoutComponent } from "./_layout/main-layout/main-layout.component";
import { AuthLayoutComponent } from "./_layout/auth-layout/auth-layout.component";

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [() => isLoggedIn(inject(AuthService), inject(Router), "")],
    canActivateChild: [
      () => isLoggedIn(inject(AuthService), inject(Router), ""),
    ],
    loadChildren: () =>
      import("./_features/main/main.module").then((m) => m.MainModule),
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    canActivate: [
      () => !isLoggedIn(inject(AuthService), inject(Router), "auth"),
    ],
    canActivateChild: [
      () => !isLoggedIn(inject(AuthService), inject(Router), "auth"),
    ],
    loadChildren: () =>
      import("./_features/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "law",
    loadChildren: () =>
      import("./_features/main/law/law.module").then((m) => m.LawModule),
  },
  {
    path: "main",
    loadChildren: () =>
      import("./_features/main/main.module").then((m) => m.MainModule),
  },
];

function isLoggedIn(
  authService: AuthService,
  router: Router,
  currentUrl: string | undefined
): boolean {
  let result: boolean = false;
  authService.isLoggedIn().subscribe((isLoggedin) => {
    if (isLoggedin && currentUrl == "auth") {
      router.navigate([""]);
    } else {
      if (!isLoggedin && currentUrl != "auth") {
        router.navigate(["auth"]);
      }
    }
    result = isLoggedin;
  });
  return result;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import {
  NgbDateStruct,
  NgbCalendar,
  NgbCalendarIslamicUmalqura,
  NgbDatepickerI18n,
  NgbDateParserFormatter,
  NgbDateAdapter,
} from '@ng-bootstrap/ng-bootstrap';

const WEEKDAYS = ['ن', 'ث', 'ر', 'خ', 'ج', 'س', 'ح'];
const MONTHS = [
  'محرم',
  'صفر',
  'ربيع الأول',
  'ربيع الآخر',
  'جمادى الأولى',
  'جمادى الآخرة',
  'رجب',
  'شعبان',
  'رمضان',
  'شوال',
  'ذو القعدة',
  'ذو الحجة',
];

@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  getMonthShortName(month: number) {
    return MONTHS[month - 1];
  }

  getMonthFullName(month: number) {
    return MONTHS[month - 1];
  }

  getWeekdayLabel(weekday: number) {
    return WEEKDAYS[weekday - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  private dateSeparatorChar: string = '-';

  // dateFormat should be "yyyy-MM-dd" or "dd-MM-yyyy" with the valid separator.
  private dateFormat = `yyyy${this.dateSeparatorChar}MM${this.dateSeparatorChar}dd`;

  parse(value: string): any {
    if (value === '') {
      return null;
    }

    const dateString: string = value;
    const dateValues = dateString.split(this.dateSeparatorChar);

    if (dateValues.length !== 3) {
      return null;
    }

    let dayIndex: number;
    let yearIndex: number;

    if (this.dateFormat === 'dd-MM-yyyy') {
      dayIndex = 0;
      yearIndex = 2;
    } else {
      dayIndex = 2;
      yearIndex = 0;
    }

    const year = Number(dateValues[yearIndex]);
    const month = Number(dateValues[1]);
    const day = Number(dateValues[dayIndex]);

    const date: NgbDateStruct = {
      year,
      month,
      day,
    };

    return date;
  }

  format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }

    const dayString: string = date.day < 10 ? `0${date.day}` : `${date.day}`;
    const monthString: string = date.month < 10 ? `0${date.month}` : `${date.month}`;
    const separator = this.dateSeparatorChar;

    const dateString =
      this.dateFormat === 'dd-MM-yyyy'
        ? `${dayString}${separator}${monthString}${separator}${date.year}`
        : `${date.year}${separator}${monthString}${separator}${dayString}`;

    return dateString;
  }
}
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

@Component({
  selector: 'app-hejri-calendar-input',
  templateUrl: './hejri-calendar-input.component.html',
  styleUrls: ['./hejri-calendar-input.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class HejriCalendarInputComponent {
  @Output() higri = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void { }

  onDateSelect(event: any) {
    const year = event.year;
    const month = event.month <= 9 ? '0' + event.month : event.month;
    const day = event.day <= 9 ? '0' + event.day : event.day;
    const finalDate = year + '-' + month + '-' + day;
    this.higri.emit(finalDate);
  }

}